<template>
  <div class="container-fluid full-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1>403</h1>
          <h4>Похоже, у вас нет доступа к ресурсу</h4>
          <p>Данный пользователь не имеет доступа к этому ресурсу Lukoil.</p>
        </div>
        <div class="col-md-3 col-md-offset-1">
          <div><img class="img_" src="../../assets/images/container.png"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Forbidden',
    metaInfo() {
        return {
            title: `Доступ к данному ресурсу ограничен - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style>
.row {
  min-height: 100vh;
  align-items: center;
}
</style>
